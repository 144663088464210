import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const hasEvent = registerLink => {
  if (registerLink === null) {
    return "/contact"
  } else {
    return registerLink
  }
}

const Template = ({ data }) => {
  const { markdownRemark: post } = data // data.markdownRemark holds your post data

  return (
    <Layout>
      <SEO title={`${post.frontmatter.title}  | Bryan, College Station, TX`} />
      <div class="p-8 bg-gray-800">
        <div class="max-w-full mx-auto">
          <div class="lg:flex  xl:flex justify-center items-center content-center text-center">
            <div class="text-center">
              <h2 class="text-2xl font-bold leading-7 text-white sm:text-3xl sm:leading-9 sm:truncate">
                {post.frontmatter.heading}
              </h2>
            </div>
            <div class="inline-flex justify-end xs:mx-auto xs:mt-6 sm:mt-6 md:mt-6">
              <span class="ml-3 shadow-sm rounded-md">
                <a
                  href={hasEvent(post.frontmatter.register_link)}
                  type="button"
                  class="px-2 py-2 border border-blue-700 bg-blue-500 leading-5 font-semibold rounded text-white hover:shadow hover:bg-blue-900 focus:outline-none focus:border-none transition duration-150 ease-in-out hover:font-bold"
                >
                  Register now
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-200 flex justify-center">
        <div class="px-4 sm:py-16 sm:px-6 lg:px-8  flex justify-center ">
          <div class="max-w-3xl md:max-w-4xl sm:max-w-full xs:max-w-full mx-auto xs:mx-4 sm:mx-4 md:mx-4 p-4 m-4 bg-white shadow rounded">
            <div
              class=" xs:w-full sm:w-full md:w-8/12 max-w-full mx-auto my-6
            px-6"
              dangerouslySetInnerHTML={{ __html: post.html }}
            ></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        heading
        price
        register_link
      }
    }
  }
`
export default Template
